@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    padding: env(safe-area-inset);
  }
  html, body, #root {
    @apply w-full h-full overflow-hidden;
  }
  body {
    touch-action: pan-x pan-y;
  }

  h1 {
    @apply text-3xl font-bold;
  }
  h2 {
    @apply text-2xl font-bold;
  }
  h3 {
    @apply text-xl font-bold;
  }
  h4 {
    @apply text-lg font-bold;
  }
  h5 {
    @apply text-base font-bold;
  }
  p {
    @apply text-base;
  }

  input {
    @apply bg-black text-white px-4 py-2 text-base w-full max-w-xs rounded mb-2
     transition-colors duration-300
     focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-gray-800
  }

  select {
    @apply bg-black text-white px-4 py-2 text-base w-full max-w-xs rounded mb-2
     transition-colors duration-300
     focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-gray-800
  }
}
